.Home01 {
  display: flex;
  flex-direction: row;
  padding: 5rem 3rem 0rem 3rem;
  justify-content: center;
  width: 100%;
}
.Home01 .one {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
}
.Home01 .one > h1 {
  color: var(--black-cl);
  padding-bottom: 0.2rem;
  border-bottom: 2px solid var(--blue-cl);
  font-size: 3.4rem;
}
.Home01 .one > .FullName {
  display: flex;
  align-items: flex-start;
  padding: 0.6rem 0;
  font-size: 1.6rem;
}
.Home01 .one > .FullName > :first-child {
  color: var(--blue-cl);
  padding-right: 0.6rem;
}
.Home01 .one > .FullName > :last-child {
  color: var(--black-cl);
}
.Home01 .one > span {
  padding: 1rem 0;
  text-align: left;
  text-overflow: clip;
  width: 70%;
  line-height: 2;
  color: var(--black-cl);
}
.Home01 .one .Link > button {
  padding: 8px 14px;
  margin: 2rem 0 3rem 0;
}
.Home01 .one .Link:hover {
  border: none;
}

/* two */
.Home01 .Two {
  position: relative;
}
.Home01 .Two > img {
  width: 350px;
  transition: 0.4s;
}
.Home01 .Two > img:hover {
  width: 360px;
}
@media only screen and (max-width: 600px) {
  .Home01 {
    flex-direction: column;
    padding: 3rem 2rem;
  }
  .Home01 .one > span {
    width: 100%;
  }
  /* two */
  .Home01 .Two > img {
    width: 100%;
    margin: 0;
  }
  .Home01 .Two > img:hover {
    width: 100%;
  }
  .Home01 .one > h1 {
    color: var(--black-cl);
    padding-bottom: 0.2rem;
    border-bottom: 2px solid var(--blue-cl);
    font-size: 2.6rem;
  }
  .Home01 .one > .FullName {
    flex-wrap: wrap;
    padding: auto 1rem;
  }
}
