.Navig {
  visibility: hidden;
  position: sticky;
  top: 0rem;
  z-index: +9999;
  width: 100%;
}
.Navig header {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem;
  margin-left: 0.2rem;
  align-items: center;
  position: absolute;
  opacity: 0.2;
  transition: 0.3s;
  width: fit-content;
  border-radius: 0 0 25% 25%;
}

.Navig header:hover {
  opacity: 1;
  border-radius: 25%;
}
.Navig header .Hun {
  font-size: 2.5rem;
  padding: 0;
  transition: 0.3s;
  color: var(--black-cl);
}
.Navig header .deBar {
  visibility: hidden;
  transition: 0.3s;
  position: absolute;
}

/* Menu Show */
.Navig .H_menu {
  visibility: hidden;
  left: 0;
  top: 0;
  transition: 0.1s;
  position: absolute;
}
.Navig .H_menu > * {
  display: flex;
  color: var(--white-cl);
  position: absolute;
  width: 100%;
}

.Navig .S_menu {
  visibility: visible;
  position: absolute;
  right: 0;
  top: 0;
  align-items: center;
  padding: 1.5rem;
  width: 100%;
  background-color: var(--white-cl);
  transition: 0.3s;
}
.Navig .S_menu > * {
  display: flex;
  padding: 0.6rem 0;
  justify-content: flex-end;
  width: 75%t;
}

@media only screen and (max-width: 600px) {
  .Navig {
    visibility: visible;
  }
}
