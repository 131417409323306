.Product {
  margin: 2rem 0;
  width: 80%;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
}
.Product .Press {
  color: var(--blue-cl);
}
/* Seleted */
.Product > .Selet {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding: 2rem 2rem;
  justify-content: flex-start;
  align-items: flex-start;
  width: 28%;
  height: fit-content;
  box-shadow: 0 0 8px rgb(228, 227, 227);
}
.Product .Selet > div {
  width: 100%;
  padding: 0.6rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
  color: var(--black-cl);
  transition: 0.2s;
}
.Product .Selet > div:hover {
  border-bottom: 2px solid var(--blue-cl);
  color: var(--blue-cl);
  padding-right: 0.5rem;
  background-color: var(--white-cl);
  transition: 0.2s;
}

.Product .Selet div > span {
  width: 100px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  font-size: 1.2rem;
}

/* Items */
.Product > .items {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: fit-content;
  height: 600px;
}
.Product > .items .card {
  display: flex;
  flex-wrap: wrap;
  margin: 0.5rem;
  width: 190px;
  height: fit-content;
  overflow: hidden;
  transition: 0.2s;
}
.Product > .items .card:hover {
  width: 200px;
}
.Product > .Card {
  width: 100%;
  height: auto;
  background-color: white;
  position: absolute;
  z-index: +99;
  top: 2rem;
  left: 0;
  padding-top: 4rem;
}

.Product .Card .content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
}
.Product .Card .content > * {
  width: 50%;
  padding: 1rem;
}
.Product .Card a > img {
  width: 600px;
  margin: 2rem;
}

.Product .Card .content .Contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.Product .Card .content .Details {
  text-align: left;
  line-height: 2;
}
.Product .Card .content button {
  width: fit-content;
}
@media only screen and (max-width: 600px) {
  .Product {
    width: 100%;
    padding: 1rem 0rem;
    flex-wrap: wrap;
  }
  .Product > .Selet {
    width: 100%;
    padding: 1rem;
  }
  .Product > .items {
    width: 100%;
    justify-content: center;
  }
  .Product > .items .card {
    width: 140px;
    justify-content: center;
  }
  .Product > .items .card:hover {
    width: 150px;
  }
  .Product .Card .content > * {
    width: 90%;
    padding: 1rem;
  }
  .Product .Card a > img {
    width: 90%;
  }
}
