.ProductShow01 {
  margin: 2rem 0;
}
.ProductShow01 div {
  width: 500px;
}

.ProductShow01 div > img {
  width: 480px;
  height: auto;
  transition: 0.2s;
}
.ProductShow01 div > img:hover {
  width: 490px;
}
@media only screen and (max-width: 600px) {
  .ProductShow01 {
    width: 100%;
    align-items: center;
    justify-content: center;
    align-self: center;
    padding: 0;
    margin: 0;
  }
  .ProductShow01 div {
    width: 100%;
    object-fit: cover;
    align-items: center;
  }
  .ProductShow01 div > img {
    max-width: 100%;
    object-fit: cover;
  }
  .ProductShow01 div > img:hover {
    width: 98%;
  }
}
