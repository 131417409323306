.Header {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 12px gray;
  align-items: center;
  border-radius: 0 0 20px 20px;
}
.Header .L img {
  width: 60px;
  padding: 0.4rem;
  margin-left: 0.5rem;
}
.Header .R ul {
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  flex-wrap: wrap;
  padding-right: 2rem;
}
.Header .R ul li {
  padding-right: 1.6rem;
  list-style: none;
}
.Header .R ul .addmin {
  border: 1px solid var(--blue-cl);
  padding: 6px;
  background-color: var(--blue-cl);
  border-radius: 8px;
  color: var(--white-cl);
  transition: 0.3s;
}
.Header .R ul .addmin:hover {
  background-color: var(--white-cl);
  color: var(--blue-cl);
}
.Link {
  text-decoration: none;
  color: var(--blue-cl);
  font-size: large;
  transition: ease-in-out 0.2s;
}
.Link:hover {
  font-weight: bold;
  padding-bottom: 0.2rem;
  border-bottom: 3px solid var(--blue-cl);
  transition: ease-in-out 0.2s;
}

@media only screen and (max-width: 600px) {
  .Header {
    visibility: hidden;
    position: absolute;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    width: 50%;
  }
  .Header .R ul {
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 2rem;
  }
}
