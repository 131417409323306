.MessageBox {
  z-index: +9999;
  width: 90%;
  height: fit-content;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  flex-direction: row;
}
.MessageBox .Box {
  position: fixed;
  bottom: 1rem;
  padding: 0.5rem;
  background-color: var(--blue-cl);
  border-radius: 25%;
  color: var(--white-cl);
  animation-name: example;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  transition: 0.2s;
}
.MessageBox .Box:hover {
  padding: 1rem;
  font-size: larger;
}
@keyframes example {
  from {
    background-color: var(--white-cl);
    color: var(--blue-cl);
    transform: translateY(-2rem);
  }
  to {
    background-color: var(--blue-cl);
    color: var(--white-cl);
    transform: translateY(0rem);
  }
}
.MessageBox .Box .Chat {
  font-size: 2rem;
}

/* CheatBox */
.MessageBox .ChatBox {
  position: fixed;
  bottom: 4rem;
  display: flex;
  flex-direction: column;
  background-color: var(--white-cl);
  box-shadow: 0 0 10px var(--blue-cl);
  border: 3px solid var(--blue-cl);
  transition: 0.1s;
}
.MessageBox .ChatBox .one {
  margin: 0;
  padding: 0.4rem;
  background-color: var(--blue-cl);
  color: var(--white-cl);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.MessageBox .ChatBox > * {
  padding: 0.4rem 1rem;
}
.H_ChatBox {
  visibility: hidden;
  transition: 0.1s;
  position: fixed;
  bottom: 0;
}
.MessageBox .two {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.MessageBox .two .L {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
}
.MessageBox .two .L span {
  transform: translateY(1rem);
  font-weight: bolder;
}
.MessageBox .two .L img {
  width: fit-content;
}
.MessageBox .two .R {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  justify-content: space-around;
  align-items: center;
}
.MessageBox .two .R .icons {
  font-size: 2rem;
  color: var(--blue-cl);
  margin: 0.4rem;
  display: inline-flex;
}
.Soc_icon {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--black-cl);
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
}

/*ChatBox Form */
.MessageBox .two .ChatForm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 1rem;
  padding: 1rem;
  background-color: var(--blue-cl);
}
/*ChatBox Form */
.MessageBox .two .ChatForm > * {
  padding: 0.2rem;
  margin: 0.4rem 0;
}
.MessageBox .two .ChatForm .F_icon {
  font-size: 5rem;
}
.MessageBox .two .ChatForm input {
  outline: none;
  border: none;
}
@media only screen and (max-width: 600px) {
}
