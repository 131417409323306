.ProductShow {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
}
.ProductShow .MouseSample {
  width: 3rem;
  border: 2px solid var(--blue-cl);
  height: 6rem;
  align-self: center;
  border-radius: 20px 20px;
  position: relative;
  margin: 2rem;
}
/* annimation */
.ProductShow .MouseSample .dot1 {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  background-color: var(--blue-cl);
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  animation-name: MouseSample;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}

@keyframes MouseSample {
  from {
    transform: translateY(1.3rem);
    border-radius: 0%;
  }
  to {
    transform: translateY(0rem);
    background-color: var(--black-cl);
  }
}
.MessageBox .Box .Chat {
  font-size: 2rem;
}

/*  */
.ProductShow .NameSample {
  color: var(--blue-cl);
}

.ProductShow .firstItem {
  width: 100%;
  margin: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (max-width: 600px) {
  .ProductShow {
    width: 100%;
    margin: 0rem;
    padding: 0 2rem;
    align-items: center;
    justify-content: center;
    align-self: center;
  }
  .ProductShow .firstItem {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .ProductShow .firstItem {
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }
  .ProductShow .firstItem > * {
    width: 100%;
    margin: 0;
    padding: 3rem 0rem;
  }
}
