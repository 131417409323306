.Footer {
  padding: 1rem 8rem 3rem 8rem;
  display: flex;
  width: 100%;
  justify-content: space-around;
  background-color: transparent;
  position: relative;
  bottom: 0;
  color: var(--blue-cl);
  border-top: 2px solid var(--blue-cl);
}
.Footer > * {
  padding: 1rem;
  line-height: 1.8;
  cursor: pointer;
  text-align: left;
}
.Footer .FT03 {
  width: 70%;
}
.Footer .FT02 {
  width: 50%;
}
.Footer .FT02 .Link,
.Footer .FT03 a {
  color: var(--blue-cl);
}

.Footer .FT04 .Map {
  width: 500px;
  border: none;
  height: 100%;
  padding-bottom: 1rem;
}

.Footer .FT03 .socail a {
  font-size: 1.7rem;
  padding-right: 2rem;
}

@media only screen and (max-width: 800px) {
  .Footer {
    flex-direction: column;
    position: relative;
    bottom: 0;
    padding: 1rem;
  }
  .Footer .FT02,
  .Footer .FT03 {
    width: 100%;
  }
  .Footer .FT04 .Map {
    width: 100%;
    height: 400px;
  }
}
