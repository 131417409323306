.Upload {
  width: 100%;
  padding: 1rem;
  margin: 2rem;
  border: 1px solid gray;
}
.Image_up {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;

  align-items: center;
}
@media only screen and (max-width: 600px) {
  .Upload {
    width: 100%;
    padding: 2rem;
    margin: 0rem;
    border: none;
  }
  .Image_up {
    flex-wrap: wrap;
    align-items: center;
    align-self: center;
  }
}
