.EmailJs {
  align-self: center;
  margin-bottom: 1rem;
  justify-content: center;
  padding-top: 3rem;
  border-top: 2px solid var(--blue-cl);
  width: 100%;
  transition: 0.2s;
}
.EmailJs:hover {
  padding-top: 3.5rem;
}
.EmailJs h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blue-cl);
  padding-bottom: 3rem;
}
.EmailJs .contents {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.EmailJs .contents .L img {
  width: 350px;
  margin-right: 2rem;
  transition: 0.2s;
}
.EmailJs .contents .L img:hover {
  width: 370px;
}
.EmailJs > button {
  padding: 6px 10px;
  margin: 2rem 0;
  font-size: 1.2rem;
}
.EmailJs .contents .R {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.EmailJs .contents .R > div {
  display: inline-block;
  padding: 1rem 6rem;
}
.EmailJs .contents .R div > label {
  color: var(--black-cl);
  font-size: 1.2rem;
  cursor: pointer;
}
.EmailJs .contents .R input,
.EmailJs .contents .R textarea {
  padding: 10px 120px 10px 10px;
  margin-left: 2rem;
  margin-right: 0;
  border: none;
  color: var(--blue-cl);
  border-bottom: 2px solid var(--blue-cl);
  outline: none;
  font-size: 1rem;
}
@media only screen and (max-width: 600px) {
  .EmailJs:hover {
    padding-top: 3rem;
  }
  .EmailJs .contents {
    flex-direction: column;
  }
  .EmailJs .contents .L img {
    width: 100%;
    padding: 1rem 3rem;
    margin: 0;
    transform: translateX(2rem);
  }
  .EmailJs .contents .L img:hover {
    width: 100%;
    margin: 0;
  }
  .EmailJs .contents .R input,
  .EmailJs .contents .R textarea {
    margin-left: 0rem;
    padding: 10px;
    margin-right: 0;
  }
  .EmailJs .contents .R div > label {
    font-size: 1rem;
  }
  .EmailJs .contents .R > div {
    display: flex;
    overflow: hidden;
    align-items: center;
    padding: 1rem;
    width: fit-content;
    object-fit: contain;
  }
  .EmailJs .contents .R div > * {
    padding: 0.8rem;
    flex-wrap: wrap;
  }
}
