.Skill {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* One */
.Skill > .one {
  width: 100%;
  padding: 3rem 4rem;
  margin: 1rem 0;
  border-radius: 10px;
  text-align: left;
}
.Skill > .one h1,
.Skill > .one h4 {
  color: var(--black-cl);
  padding-bottom: 1rem;
  transition: 0.2s;
  text-align: left;
}
.Skill > .one h1:hover {
  font-size: 2.2rem;
}
.Skill > .one h4:hover {
  font-size: 1.2rem;
}
.Skill .one > div {
  align-items: center;
  text-align: left;
  overflow: auto;
  line-height: 1.8;
  width: 60%;
}

/* Two */
.Skill > .two {
  width: 94%;
}
.Skill .items {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: start;
}
.Skill .items .item {
  width: 240px;
  position: relative;
  margin: 1rem;
  justify-content: start;
}

.Skill .items .item > .content {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 150px;
  width: 100%;
  background-color: transparent;
  overflow: hidden;
  padding-top: 10rem;
  transition: 0.2s;
  text-align: left;
  cursor: pointer;
}
.Skill .items .item > .content:hover {
  display: flex;
  align-items: flex-end;
  padding: 1rem;
  background-color: rgba(223, 221, 221, 0);
  height: 100%;
  width: 100%;
  font-weight: bolder;
  text-shadow: 0 0 12px var(--white-cl);
  color: var(--black-cl);
}

.Skill .items .item img {
  width: 240px;
  height: 240px;
  overflow: hidden;
  object-fit: cover;
  height: max-content;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  /* one */
  .Skill > .one {
    padding: 3rem 2rem;
    margin: 1rem 0;
    border-radius: 10px;
  }
  .Skill .one > div {
    padding: 0;
    width: 100%;
  }
  /* Items */
  .Skill > .two {
    width: 90%;
  }
  .Skill .items {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
  }
  .Skill .items .item {
    width: 90%;
    margin: 1.5rem;
  }

  .Skill .items .item img {
    width: 100%;
  }
  .Skill .items .item img:hover {
    width: 98%;
  }
}
