* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}
.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ackground: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);
  /* background: url("") center no-repeat;
  backdrop-filter: blur(5px);
  background-size: cover; */
}
.App > * {
  width: 80%;
}

:root {
  --white-cl: #f7fbfc;
  --black-cl: #363636;
  --blue-cl: #2bb7bd;
}
.MainName {
  height: auto;
  width: 100%;
  background-color: var(--blue-cl);
  color: var(--white-cl);
  padding: 0.8rem;
  z-index: +9999;
  font-size: smaller;
}
/*  button */
button {
  background-color: transparent;
  color: var(--blue-cl);
  border: 2px solid var(--blue-cl);
  border-radius: 10px;
  transition: 0.3s;
  font-size: 16px;
}
button:hover {
  border: none;
  background-color: var(--blue-cl);
  color: var(--white-cl);
  font-weight: bolder;
}
input,
textarea {
  background-color: transparent;
}
span {
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  * {
    margin: 0;
    padding: 0;
    box-sizing: 0;
  }
  .App > * {
    width: 100%;
  }
}
/* 3D */
body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: linear-gradient(
    315deg,
    rgb(216, 248, 230) 3%,
    rgb(240, 234, 245) 38%,
    rgb(157, 245, 233) 68%,
    rgb(255, 255, 255) 98%
  );
  animation: gradient 15s ease infinite;
  background-size: 300% 300%;
  background-attachment: fixed;
}

@keyframes gradient {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.wave {
  background: rgba(115, 230, 230, 0.25);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 16rem;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
    transform: translateX(1);
  }

  25% {
    transform: translateX(-25%);
  }

  50% {
    transform: translateX(-50%);
  }

  75% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(1);
  }
}
