.Addmin {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Addmin > form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10rem 0;
}
.Addmin form > * {
  padding: 1rem 2rem;
}
.Addmin form > .L {
  width: 460px;
  text-align: left;
}
.Addmin form .L .Conect {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.Addmin form .L > h1 {
  transition: 0.4s;
}
.Addmin form .L > h1:hover {
  font-size: 2.2rem;
}

.Addmin form .L .Conect .C_Link {
  border: none;
  font-size: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}
.Addmin form .L .Conect .C_Link > span {
  padding-left: 0.5rem;
}
.Addmin form .L > *,
.Addmin form .R > * {
  padding: 1rem 0;
}

/* R site form */
.Addmin form .R {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0rem 12rem;

  width: 560px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  transition: 0.6s;
  transform: translateX(6rem);
  transition: ease-in-out 0.5s;
}
.Addmin form .R:hover {
  background-image: url("https://quantumhunts.com/user/assets/images/hero/hiring-manager-quantumhunts.gif");
  background-size: contain;
  background-position: center;
  transition: 0.4s;
}
.Addmin form .R h1 {
  margin-bottom: 3rem;
}
.Addmin form .R > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Addmin form .R input {
  padding: 10px;
  width: 100%;
  border: none;
  background-color: rgb(255, 255, 255);

  font-size: 1rem;
}
.Addmin form .R div .icon {
  transform: translateX(-2rem);
  color: var(--blue-cl);
  font-size: 1.8rem;
}
.Addmin form .R button {
  padding: 8px 16px;
}
@media only screen and (max-width: 600px) {
  .Addmin {
    width: 100%;
  }
  .Addmin > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0rem;
    padding: 5rem 0;
  }
  .Addmin form > .L,
  .Addmin form > .R {
    width: 100%;
  }
  .Addmin form .R {
    transform: translateX(0rem);
  }
}
