.ProductShow01R {
  width: 100%;
  top: 0;
  line-height: 1.8;
}
.ProductShow01R .one {
  display: flex;
  align-self: center;
  align-items: center;
  text-align: right;
  justify-content: flex-end;
}
.ProductShow01R .one .L {
  width: 60%;
  height: 6px;
  background-color: var(--blue-cl);
  margin-right: 2rem;
}
.ProductShow01R .one h1 {
  line-height: 0.8;
  margin-bottom: 1rem;
  color: var(--black-cl);
}
/* two */
.ProductShow01R .two {
  text-align: right;
  width: 100%;
  padding: 0 0 0 20%;
}
.ProductShow01R > div {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  padding: 4rem 0 1rem 0;
}
.ProductShow01R div .Link > button {
  position: relative;
  padding: 8px 20px;
  right: 0;
}
.ProductShow01R div .Link:hover {
  border: none;
}
/* Card */
.ProductShow01R .parsen {
  display: flex;
  align-items: center;
  align-self: flex-end;
}
.ProductShow01R .parsen .stars {
  margin-right: 0rem;
  border-bottom: 2px solid var(--blue-cl);
}
.ProductShow01R .parsen .stars > .star {
  margin: 0 0.3rem;
  font-size: 1.4rem;
}
.ProductShow01R .parsen .likes {
  margin: 0rem;
  font-size: 1.4rem;
  border: 2px solid var(--blue-cl);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  transition: 0.2s;
}
.ProductShow01R .parsen .likes:hover {
  border: none;
  background-color: var(--blue-cl);
  color: var(--white-cl);
}
.ProductShow01R .parsen .likes .like {
  margin: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ProductShow01R .parsen .likes .like span {
  font-size: 0.8rem;
}

@media only screen and (max-width: 600px) {
  .ProductShow01R {
  }
  .ProductShow01R .parsen {
    flex-direction: column;
    padding: 0;
  }
  .ProductShow01R .parsen > * {
    margin: 2rem 0;
  }
}
