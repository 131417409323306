.CT_State {
  width: 100%;
  height: fit-content;
  padding: 2rem 0;
}
.CT_State .content {
  /* background-image: url("../../Avatar/State.png"); */
  /* background-image: url("https://i.pinimg.com/originals/45/4a/ed/454aed2eb266684fdda840a799951d73.gif"); */
  /* background-image: url("https://res.cloudinary.com/dxni450l4/image/upload/v1708155551/t0iibquvbuoqxno9noxl.png"); */
  width: auto;
  height: 100%;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
.CT_State .content:hover {
  background-size: 220px;
}
/* L */
.CT_State .content .L {
  width: 100%;
  text-align: left;
  padding: 1rem 3rem 1rem 2rem;
  color: var(--black-cl);
}
.CT_State .content .L > h1 {
  text-decoration: underline;
  padding-bottom: 16px;
  color: var(--blue-cl);
}

/* icons */
.CT_State .content .L .iconItem {
  padding: 3rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.CT_State .content .L .iconItem .item {
  margin: 0.4rem;

  border: 2px solid var(--blue-cl);
  padding: 0.1rem 1rem;
  width: fit-content;
  text-align: center;
  border-radius: 10px;
  color: var(--blue-cl);
  transition: 0.2s;
  display: flex;
  flex-direction: column;
}
.CT_State .content .L .iconItem .item > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.CT_State .content .L .iconItem .item:hover {
  padding: 0.2rem 1.1rem;
  border: none;
  background-color: var(--blue-cl);
  color: var(--white-cl);
}

.CT_State .content .L .iconItem .item > * {
  padding: 0.2rem 0;
}
/* R */
/* R */
.CT_State .content .R {
  padding: 1rem 4rem 1rem 0rem;
  color: var(--blue-cl);
}
.CT_State .content .R > h3 {
  padding: 1rem;
}
.CT_State .content .R img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .CT_State {
    height: fit-content;
  }
  .CT_State .content {
    background-image: none;
    background-position: center;
    background-size: 330px;
    background-repeat: no-repeat;
    flex-direction: column;
    padding: 2rem 1rem;
  }
  .CT_State .content:hover {
    background-size: 360px;
  }
  .CT_State .content .R {
    padding: 2rem 0;
  }
  .CT_State .content .L .iconItem {
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
  }
  .CT_State .content .L .iconItem .item {
    padding: 0.3srem;
  }
  .CT_State .content .R img {
    width: 80%;
    padding: 0rem 0;
  }
}
